.notes{
    position: fixed;
    width: 100%;
    transition: transform 0.5s ease-out;
    transform: translateY(-100%);
    background-color: beige;
    height: 80vh;
    z-index: 2;
}

.notes-open {
    transform: translateY(0%);
}

.notes-form {
    text-align: center;
    height: 100%;
}

.notes-label {
    display: block;
    padding: 30px;
    font-size: 40px;
}

#notes-field {
  padding: 20px;
  width: 80%;
  height: 60%;
  font-size: 24px;
}

.notes-tab {
    position: absolute;
    background-color: white;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    bottom: -80px;
    width: 300px;
    height: 80px;
    margin: 0px auto;
    border-radius: 0px 0px 30% 30%;
}

.notes-tab h3 {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.notes, .notes-tab {
    box-shadow: 0px 8px rgba(10, 10, 10, 0.5);
}

.notes-tab:hover {
    /*bottom: -32%;*/
    box-shadow: 0px 4px rgba(7, 7, 7, 0.8);
    cursor: pointer;
}

@media only screen and (min-width: 1201px) {
    .notes{
        background-image: url("../img/shutterstock_paper_min.jpg");
    }
  }

  @media only screen and (max-height: 500px) {
    #notes-field {
      max-height: 100px;
    }
  }