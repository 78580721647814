.value-select {
  position: relative;
  grid-area: select;
}

.value-label {
  position: absolute;
  bottom: 30px;
  right: 10px;
}

.value-label input {
  width: 7ch;
  padding: 10px;
  font-size: 30px;
  text-align: right;
  font-family: normalidad-text, sans-serif;
}

@media only screen and (min-width: 320px) {
  .value-label input {
    font-size: 40px;
  }
}

@media only screen and (min-width: 500px) {
  .value-label input {
    font-size: 50px;
  }
}

@media only screen and (min-width: 769px) {
  .value-label input {
    font-size: 80px;
  }
}