.App {
  text-align: center;
}

.global-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

@media only screen and (min-width: 769px) {
  .global-bg {
    background-image: url("./img/shutterstock_marble_min.jpg");
  }
}