.input-area{
    position: relative;
    display: grid;
    grid-template-areas:
    "select select select metric";
    align-items: flex-end;
    height: 140px;
    min-height: 200;
    background-color: #000012;
    padding-top: 80px;
}

.form {
  height: 100%; 
  text-align: center;
}

@media only screen and (min-width: 500px) {

  .input-area {
    height: 220px;
    grid-template-areas: 
    "select select metric";
  }
}

@media only screen and (min-width: 1201px) {

  .input-area {
    grid-template-areas: 
    "select metric";
  }
}